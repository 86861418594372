import { GEO_MAPPING_BASE_URL } from "../config";
import { tsvToJSON, changeToGroup, parseTSV, parseTSVtoJSON } from "./utils";

export const NAME = "IMMIX";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";

// export const tsvData =
//     `address     	city	state	zip	name	phone	group	url	email\tnote\topportunities\tLeadBySource
// 1206 VAN NESS AVE	FRESNO	CA	93721	Wells Fargo	(800) 869-3577	ATM	https://www.wellsfargo.com	info@wellsfargo.com\tLorem ipsum dolor sit amet, consetetur sadips-cing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat\t100\tcolleague
// 50 W SAN FERNANDO ST 	SAN JOSE	CA	95113	Wells Fargo	(408) 689-6800	Branch	https://www.wellsfargo.com	info@wellsfargo.com\tLorem ipsum dolor sit amet, consetetur sadips-cing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat\t150\tNewspaper
// 5763 CHRISTIE AVE	EMERYVILLE	CA	94608	Wells Fargo	(800) 869-3557	Loans	https://www.wellsfargo.com	info@wellsfargo.com\tLorem ipsum dolor sit amet, consetetur sadips-cing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat\t200
// 1121 LAS VEGAS	LAS VEGAS	NV	89104	Wells Fargo	(702) 464-3138	Branch	https://www.wellsfargo.com	info@wellsfargo.com\tLorem ipsum dolor sit amet, consetetur sadips-cing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat\t250
// 1300 22ND ST	BALERSFIELD	CA	93301	Wells Fargo	(661) 861-9971	Mortgage	https://www.wellsfargo.com	info@wellsfargo.com\tLorem ipsum dolor sit amet, consetetur sadips-cing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat\t300
// 232 E 2ND ST	LOS ANGELES	CA	90012	Wells Fargo	(213) 335-6549	Branch	https://www.wellsfargo.com	info@wellsfargo.com\tLorem ipsum dolor sit amet, consetetur sadips-cing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat\t350
// 101 W BROADWAY STE	SAN DIEGO	CA	92101	Wells Fargo	(800) 869-3557	Branch	https://www.wellsfargo.com	info@wellsfargo.com\tLorem ipsum dolor sit amet, consetetur sadips-cing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat\t400`.toUpperCase();
// Lahore	SAN DIEGO	CA	92101	Wells Fargo	(800) 869-3557	Branch	https://www.wellsfargo.com	info@wellsfargo.com
// Faisalabad	LOS ANGELES	CA	90012	Wells Fargo	(213) 335-6549	Branch	https://www.wellsfargo.com	info@wellsfargo.com
// Multan	SAN DIEGO	CA	92101	Wells Fargo	(800) 869-3557	Branch	https://www.wellsfargo.com	info@wellsfargo.com
// Sheikhupura	SAN DIEGO	CA	92101	Wells Fargo	(800) 869-3557	Branch	https://www.wellsfargo.com	info@wellsfargo.com
// Sargodha	SAN DIEGO	CA	92101	Wells Fargo	(800) 869-3557	Branch	https://www.wellsfargo.com	info@wellsfargo.com`;
export const tsvData =
    `WO #	Company Name	CEO Name	First	Name	Address    \tCity 	 State 	 Phone 	 Ticker Symbol	 Sector	 Industry	Emails	Status	Camera#	Sales (Sq Ft)	WareH (Sq Ft)	Total (Sq Ft)	Mon Open	Mon Close	Tue Open	Tue Close	Wed Open	Wed Close	Thur Open	Thur Close	Fri Open 	Fri Close	Sat Open	Sat Close	Sun Open 	Sun Close	Delivery Day	Systems	Cnt#	Cbl Type	Cbl Sqft	SrvTech	COG	Hours	GROSS	VendorPay
2290	Walmart Super Center	C. Douglas McMillon	Aaron	Youket	6649 N Blue Gum St	Orleans	LA	607-348-4458	WMT	Retailing	General Merchandisers	AARONYOUKET@xoommaps.com	Refresh	3.0	4833	2611	7444	900	900	900	900	900	900	900	900	900	900	900	900	1100	700	Tue, Thu	ADT	4	CAT7	22500	Chuck	25.0	2	250	50.0
2310	Berkshire Hathaway	Warren E. Buffett	Adam	Gingold	8 W Cerritos Ave #54	Gloucester	NJ	845-754-3307	BRK.A	Financials	Insurance: Property and Casualty (Stock)	Support@electronicworlddepot.iso	DeCom	23.0	1850	685	2535	900	900	900	900	900	900	900	900	900	900	900	900	1100	700	Mon, Wed	Salton	2	COAX	11111	Kevin	25.75	2	150	51.5
2360	Apple	Timothy D. Cook	ADAM	MAULT	639 Main St	Anchorage	AK	716.581.0620	AAPL	Technology	Computers, Office Equipment	armault98@xoommaps.com	Refresh	6.0	2707	1659	4366	900	900	900	900	900	900	900	900	900	900	900	900	1100	700	Mon	HID	8	CAT5	50500	Steve	24.0	1	150	24.0
2390	UnitedHealth Group	David S. Wichmann	Adam	Ward	34 Center St	Butler	OH	315-283-3776	UNH	Health Care	Health Care: Insurance and Managed Care	adam.ward10@xoommaps.com	New	3.0	1613	2627	4240	1100	700	1100	700	900	900	900	900	900	900	900	900	1100	700	Fri	HoneyWell	33	CAT7	60000	Brian	30.0	2	250	60.0
2425	McKesson	John H. Hammergren	Adeye	Hailu	3 Mcauley Dr	Ashland	OH	310-691-3699	MCK	Wholesalers	Wholesalers: Health Care	it.solutionsinc@live.iso	DeCom	22.0	1358	1407	2765	1100	700	1100	700	900	900	900	900	900	900	900	900	1100	700	Tue	SimpliSafe	88	CAT6	22500	Mark	25.54	1	125	25.54
2460	CVS Health	Larry J. Merlo	Adrian	Osio	7 Eads St	Cook	IL	315-542-6026	CVS	Health Care	Health Care: Pharmacy and Other Services	adrian@a-znet.iso	Refresh	7.0	1674	1495	3169	1100	700	1100	700	900	900	900	900	900	900	900	900	1100	700	Thu	Envoy	55	COAX	10200	Dan	25.0	2	250	50.0
2495	Amazon.com	Jeffrey P. Bezos	Ahmed	Abdullah	7 W Jackson Blvd	Santa Clara	CA	917-567-3945	AMZN	Retailing	Internet Services and Retailing	jyemi1215@xoommaps.com	New	23.0	3211	2368	5579	900	900	900	900	900	900	900	1000	900	1000	900	900	1100	700	Mon, Thu	Bosch	33	CAT5	11111	Charles	25.75	2	150	51.5
2530	General Motors	Mary T. Barra	Alistar	Baker	228 Runamuck Pl #2808	Baltimore City	MD	917-567-3947	GM	Motor Vehicles & Parts	Motor Vehicles and Parts	alistar.w.baker@xoommaps.com	Refresh	9.0	1872	1852	3724	1100	700	1100	700	1000	700	1000	700	1000	900	900	900	1100	700	Wed	Johnson	111	CAT6	60000	Hamza	24.0	1	150	24.0
2565	Ford Motor	James P. Hackett	Allan	Fong	2371 Jerrold Ave	Montgomery	PA	917-567-3948	F	Motor Vehicles & Parts	Motor Vehicles and Parts	ajfong1756@xoommaps.com	New	1.0	8383	6310	14693	900	1000	900	1000	900	1000	900	1000	900	1000	900	900	1100	700	Mon, Tue, Wed, Thu	ADT	32	COAX	22500	Danish	30.0	2	250	60.0
2600	AmerisourceBergen	Steven H. Collis	Allen	Dittmann	37275 St  Rt 17m M	Suffolk	NY	917-567-3949	ABC	Wholesalers	Wholesalers: Health Care	illfixurpc@xoommaps.com	DeCom	22.0	180	11844	12024	900	500	900	500	900	500	900	500	900	500	900	900	1100	700	Mon, Tue, Wed, Thu	Kisi	55	CAT5	10200	Kelly	25.54	1	125	25.54
2635	Chevron	Michael K. Wirth	amaury	helena	25 E 75th St #69	Los Angeles	CA	917-567-3950	CVX	Energy	Petroleum Refining	chekeo@netzero.iso	Refresh	6.0	1878	2322	4200	1100	700	1100	700	900	900	900	900	900	900	900	900	1100	700	Fri	Salton	66	CAT7	11111	Chuck	25.0	2	250	50.0
2670	Costco	W. Craig Jelinek	Andre	Barnett	56 E Morehead St	Webb	TX	917-567-3952	COST	Retailing	General Merchandisers	dispatch@tswsupport.iso	DeCom	9.0	2038	1423	3461	1100	700	1100	700	900	900	900	900	900	900	900	900	1100	700	Wed	HoneyWell	6	COAX	60000	Kevin	25.75	2	150	51.5
2705	Verizon	Lowell C. McAdam	Andre	Brown	73 State Road 434 E	Maricopa	AZ	917-567-3953	VZ	Telecommunications	Telecommunications	brown747@xoommaps.com	Refresh	22.0	1622	1138	2760	1100	700	1100	700	900	900	900	900	900	900	900	900	1100	700	Tue	SimpliSafe	2	CAT5	10200	Steve	24.0	1	150	24.0
2740	Kroger	W. Rodney McMullen	Andre	Toomer	69734 E Carrillo St	Warren	TN	917-567-3954	KR	Food & Drug Stores	Food and Drug Stores	ayden.shayne@xoommaps.com	New	5.0	2094	1480	3574	1100	700	1100	700	900	900	900	900	900	900	900	900	1100	700	Wed	Envoy	8	CAT7	11111	Brian	30.0	2	250	60.0
2775	General Electric	John L. Flannery Jr.	Andre	Hinds	322 New Horizon Blvd	Milwaukee	WI	917-567-3955	GE	Industrials	Industrial Machinery	anedd2020@xoommaps.com	DeCom	87.0	160	7340	7500	900	500	900	500	900	500	900	500	900	500	900	900	1100	700	Tue, Fri	Bosch	6	CAT6	50500	Mark	25.54	1	125	25.54
2810	JPMorgan Chase	James Dimon	Andrel	Lawson	394 Manchester Blvd	Winnebago	IL	718-812-2985	JPM	Financials	Commercial Banks	andrellawson@xoommaps.com	New	4.0	2624	1876	4500	900	900	900	900	900	900	900	900	900	900	900	900	1100	700	Tue	Johnson	55	CAT5	22500	Charles	25.0	2	250	50.0
2845	Fannie Mae	Timothy J. Mayopoulos	Andrew	Gleason	6 S 33rd St	Delaware	PA	347-256-4549	FNMA	Financials	Diversified Financials	americomp2000@xoommaps.com	DeCom	23.0	1693	992	2685	900	930	900	930	900	930	900	930	900	930	900	930	1100	700	Mon, Thu	ADT	33	CAT7	10200	Mio	25.75	2	150	51.5
2880	Alphabet	Larry Page	Angel	Valle	6 Greenleaf Ave	Santa Clara	CA	845.476.5904	GOOGL	Technology	Internet Services and Retailing	avallesr@verizon.iso	Refresh	6.0	7061	4897	11958	900	1000	900	1000	900	1000	900	1000	900	1000	900	1000	1100	700	Mon, Tue, Wed, Fri	Kisi	66	CAT6	11111	Hamza	24.0	1	150	24.0
2915	Home Depot	Craig A. Menear	Angela	Greene	618 W Yakima Ave	Dallas	TX	718-877-0903	HD	Retailing	Specialty Retailers: Other	greenhouse001@xoommaps.com	New	3.0	2790	1635	4425	900	1000	900	1000	900	1000	900	1000	900	1000	900	1000	1100	700	Mon, Wed	Salton	111	COAX	50500	Danish	30.0	2	250	60.0
2950	Bank of America Corp.	Brian T. Moynihan	Anthony	Morgan	74 S Westgate St	Albany	NY	914-302-2359	BAC	Financials	Commercial Banks	viper2gfam@xoommaps.com	DeCom		7784	7964	15748	900	1000	900	1000	900	1000	900	1000	900	1000	900	1000	1100	700	Mon, Tue, Wed, Fri	HID	32	CAT5	60000	Kelly	25.54	1	125	25.54
2985	Boeing	Dennis A. Muilenburg	Anthony	Santiago	86 Nw 66th St #8673	Johnson	KS	917-528-6014	BA	Aerospace & Defense	Aerospace and Defense	the411.t@xoommaps.com	DeCom	5.0	5863	3791	9654	900	700	900	700	900	700	900	700	900	700	900	700	1100	500	Mon, Thu	Envoy	4	COAX	11111	Kevin	25.0	2	250	50.0
3020	Phillips	Greg C. Garland	Anthony	Mendez	2 Cedar Ave #84	Talbot	MD	917-577-6500	PSX	Energy	Petroleum Refining	mendez164@xoommaps.com	Refresh	9.0	1665	1480	3145	1100	700	1100	700	900	900	900	900	900	900	900	900	1100	600	Fri	Bosch	6	CAT5	50500	Steve	25.75	2	150	51.5
3055	Anthem	Gail K. Boudreaux	Anthony	D'Agostino	90991 Thorburn Ave	New York	NY	845-321-0744	ANTM	Health Care	Health Care: Insurance and Managed Care	anthony.dag@xoommaps.com	New	1.0	5957	5712	11669	900	700	900	700	900	700	900	700	900	700	900	700	1200	500	Mon, Wed	OpenPath	2	CAT7	60000	Brian	24.0	1	150	24.0
3090	Microsoft	Satya Nadella	Anthony	Joseph	386 9th Ave N	Montgomery	TX	347-607-0123	MSFT	Technology	Computer Software	ant.josh@xoommaps.com	DeCom	22.0	1672	1328	3000	900	900	900	900	900	900	900	900	900	900	900	900	1100	500	Fri	Johnson	8	CAT6	10200	Mark	30.0	2	250	60.0
3125	Valero Energy	Joseph W. Gorder	Anthony	Rodriguez	74874 Atlantic Ave	Franklin	OH	607-348-4459	VLO	Energy	Petroleum Refining	anrodcomputerservices@xoommaps.com	Refresh	6.0	1564	926	2490	1100	700	1100	700	900	900	900	900	900	900	900	900	0	0	Fri	ADT	4	COAX	11111	Dan	25.54	1	125	25.54
3160	Citigroup	Michael L. Corbat	Anton	Harrison	366 South Dr	Dona Ana	NM	718-300-3426	C	Financials	Commercial Banks	anton-harrison@xoommaps.com	New	3.0	6202	3237	9439	900	1000	900	1000	900	1000	900	1000	900	1000	900	1000	1100	700	Mon, Tue, Thu, Fri	Kisi	6	CAT5	50500	Charles	25.0	2	250	50.0
3195	Comcast	Brian L. Roberts	Arnold	Beal	45 E Liberty St	Bergen	NJ	845-754-3308	CMCSA	Telecommunications	Telecommunications	arnieb@frontiernet.iso	DeCom	9.0	10025	7739	17764	900	1000	900	1000	900	1000	900	1000	900	1000	900	1000	1100	700	Mon, Tue, Wed, Thu, Fri	Salton	2	CAT7	60000	Mio	25.75	2	150	51.5
3230	Dell Technologies	Michael S. Dell	Bernard	Roberts	2742 Distribution Way	New York	NY	315-283-3777	CMCSA	Technology	Computers, Office Equipment	BernardRoberts.K@xoommaps.com	New	5.0	3227	1358	4585	900	900	900	900	900	900	900	900	900	900	900	900	1100	600	Mon, Fri	HoneyWell	33	COAX	10200	Danish	24.0	1	150	24.0
3265	State Farm Insurance Cos.	Michael L. Tipsord	Bernard	Brifu	426 Wolf St	Jefferson	LA	310-691-3700	IBM	Financials	Insurance: Property and Casualty (Mutual)	bernardk.brifu@xoommaps.com	DeCom	87.0	1363	4593	5956	1100	900	1100	900	1100	900	1100	900	1100	900	1100	900	0	0	Tue	SimpliSafe	88	CAT5	11111	Kelly	30.0	2	250	60.0
3300	Johnson & Johnson	Alex Gorsky	Brant	Monroe	128 Bransten Rd	New York	NY	315-542-6027	CMCSA	Health Care	Pharmaceuticals	brant_m@xoommaps.com	Refresh	3.0	2616	1275	3891	900	900	900	900	900	900	900	900	900	900	900	900	1100	700	Mon, Fri	Envoy	55	CAT7	50500	Chuck	25.54	1	125	25.54
3335	Freddie Mac	Donald H. Layton	Brett	Colton	17 Morena Blvd	Ventura	CA	917-567-3957	IBM	Financials	Diversified Financials	bec2957@aol.iso	New	4.0	5960	3320	9280	900	900	900	900	900	900	900	900	900	1000	900	1000	1100	700	Mon, Tue, Wed, Fri	Bosch	33	CAT6	60000	Moosie	25.0	2	250	50.0
3370	Target	Brian C. Cornell	Brian	Reyes	775 W 17th St	Bexar	TX	917-567-3958	CMCSA	Retailing	General Merchandisers	the411.t@xoommaps.com	DeCom	23.0	1506	2133	3639	1100	700	1100	700	900	900	900	900	900	900	900	900	0	0	Thu	OpenPath	66	COAX	22500	Kevin	25.75	2	150	51.5
3405	Lowe’s	Robert A. Niblock	Brian	Reed	6980 Dorsett Rd	Dickinson	KS	917-567-3959	LOW	Retailing	Specialty Retailers: Other	reedb88@charter.iso	Refresh	6.0	1627	4475	6102	1100	900	1100	900	1100	900	1100	900	1100	900	1100	900	1200	500	Wed	Johnson	111	CAT5	10200	Steve	24.0	1	150	24.0
3440	Marathon Petroleum	Gary R. Heminger	Brian	Reed	2881 Lewis Rd	Crook	OR	917-567-3960	MPC	Energy	Petroleum Refining	reedb88@charter.iso	New	3.0	1804	1954	3758	1100	700	1100	700	900	900	900	900	900	900	900	900	1100	700	Wed	ADT	32	CAT7	11111	Brian	30.0	2	250	60.0
3475	Procter & Gamble	David S. Taylor	Brian	Glasser	7219 Woodfield Rd	Johnson	KS	917-567-3961	PG	Household Products	Household and Personal Products	obezor@xoommaps.com	DeCom		1135	852	1987	0	0	1100	600	1100	600	1100	600	1100	600	1100	600	0	0	Fri	Kisi	55	CAT6	50500	Mark	25.54	1	125	25.54
3510	MetLife	Steven A. Kandarian	Brian	Mackey	1048 Main St	Fairbanks North Star	AK	917-567-3962	MET	Financials	Insurance: Life, Health (stock)	bl22619@xoommaps.com	Refresh	7.0	5230	3429	8659	900	900	900	900	900	900	900	900	900	900	900	900	1100	700	Tue, Fri	Salton	66	COAX	60000	Dan	25.0	2	250	50.0
3545	UPS	David P. Abney	Byron	Allen	678 3rd Ave	Miami-Dade	FL	917-567-3963	UPS	Transportation	Mail, Package, and Freight Delivery	WizByron@xoommaps.com	New	23.0	2838	3182	6020	900	900	900	900	900	900	900	900	900	900	900	900	1100	700	Tue, Fri	HID	4	CAT5	10200	Charles	25.75	2	150	51.5
3580	PepsiCo	Indra K. Nooyi	Byron	Allen	20 S Babcock St	Fairbanks North Star	AK	917-567-3964	PEP	Food, Beverages & Tobacco	Food Consumer Products	Byron@SaintDoctor.iso	DeCom	5.0	1416	2225	3641	0	0	1100	600	1100	600	1100	600	1100	600	1100	600	0	0	Fri	HoneyWell	6	CAT7	11111	Mio	24.0	1	150	24.0
3615	Intel	Brian M. Krzanich	Calvin	Long	2 Lighthouse Ave	Hennepin	MN	917-567-3965	INTC	Technology	Semiconductors and Other Electronic Components	AARONYOUKET@xoommaps.com	Refresh	9.0	977	1155	2132	1100	700	1100	700	900	900	900	900	900	900	900	900	0	0	Thu	SimpliSafe	2	CAT6	50500	Hamza	30.0	2	250	60.0
3650	DowDuPont	Edward D. Breen	Carl	Barkley	38938 Park Blvd	Suffolk	MA	917-567-3966	DWDP	Chemicals	Chemicals	abassjnr2000@xoommaps.com	New	1.0	2612	3242	5854	1100	700	1100	700	900	900	900	900	900	900	900	900	1100	600	Fri	Envoy	8	COAX	60000	Danish	25.54	1	125	25.54
3685	Archer Daniels Midland	Juan R. Luciano	Carl E	Davis Jr	5 Tomahawk Dr	Los Angeles	CA	917-567-3967	ADM	Food, Beverages & Tobacco	Food Production	Support@electronicworlddepot.iso	DeCom	22.0	1024	2464	3488	1100	700	1100	700	900	900	900	900	900	900	900	900	0	0	Wed	Bosch	6	CAT5	22500	Kelly	25.0	2	250	50.0
3720	Aetna	Mark T. Bertolini	Carlton	Jones	762 S Main St	Dane	WI	917-567-3968	AET	Health Care	Health Care: Insurance and Managed Care	armault98@xoommaps.com	Refresh	6.0	2289	2144	4433	900	900	900	900	900	900	900	900	900	900	900	900	1100	700	Mon, Wed	OpenPath	88	CAT7	10200	Chuck	25.75	2	150	51.5
`.toUpperCase();
// export const rawData = tsvToJSON(tsvData);
export const rawData = parseTSV(tsvData);
console.log("RAW DATA: ", rawData);
export const groupedData = changeToGroup(rawData, "GROUP");

let uploadPath = `${GEO_MAPPING_BASE_URL}uploads`;
export let timezonesPath = `${uploadPath}/timezones`;
export let statesPath = `${uploadPath}/states`;

export let KML_TIMEZONES = [
    `${timezonesPath}/-10.kml`,
    `${timezonesPath}/-08.kml`,
    `${timezonesPath}/-07.kml`,
    `${timezonesPath}/-06.kml`,
    `${timezonesPath}/-05.kml`,
    `${timezonesPath}/-04.kml`,
];

export const KML_STATES_URLS = [
    // USA
    `${statesPath}/AL.kml`,
    `${statesPath}/AK.kml`,
    `${statesPath}/AZ.kml`,
    `${statesPath}/AR.kml`,
    `${statesPath}/CA.kml`,
    `${statesPath}/CO.kml`,
    `${statesPath}/CT.kml`,
    `${statesPath}/DE.kml`,
    `${statesPath}/FL.kml`,
    `${statesPath}/GA.kml`,
    `${statesPath}/HI.kml`,
    `${statesPath}/ID.kml`,
    `${statesPath}/IL.kml`,
    `${statesPath}/IN.kml`,
    `${statesPath}/IA.kml`,
    `${statesPath}/KS.kml`,
    `${statesPath}/KY.kml`,
    `${statesPath}/LA.kml`,
    `${statesPath}/ME.kml`,
    `${statesPath}/MD.kml`,
    `${statesPath}/MA.kml`,
    `${statesPath}/MI.kml`,
    `${statesPath}/MN.kml`,
    `${statesPath}/MS.kml`,
    `${statesPath}/MO.kml`,
    `${statesPath}/MT.kml`,
    `${statesPath}/NE.kml`,
    `${statesPath}/NV.kml`,
    `${statesPath}/NH.kml`,
    `${statesPath}/NJ.kml`,
    // `${statesPath}/NM.kml`,
    `${statesPath}/NY.kml`,
    `${statesPath}/NC.kml`,
    `${statesPath}/ND.kml`,
    `${statesPath}/OH.kml`,
    `${statesPath}/OK.kml`,
    `${statesPath}/OR.kml`,
    `${statesPath}/PA.kml`,
    `${statesPath}/RI.kml`,
    `${statesPath}/SC.kml`,
    `${statesPath}/SD.kml`,
    `${statesPath}/TN.kml`,
    `${statesPath}/TX.kml`,
    `${statesPath}/UT.kml`,
    `${statesPath}/VT.kml`,
    `${statesPath}/VA.kml`,
    `${statesPath}/WA.kml`,
    `${statesPath}/WV.kml`,
    `${statesPath}/WI.kml`,
    `${statesPath}/WY.kml`,
    // CANADA
    `${statesPath}/AB.kml`,
    `${statesPath}/BC.kml`,
    `${statesPath}/MB.kml`,
    `${statesPath}/NB.kml`,
    `${statesPath}/NL.kml`,
    `${statesPath}/NT.kml`,
    `${statesPath}/NS.kml`,
    `${statesPath}/NU.kml`,
    `${statesPath}/ON.kml`,
    `${statesPath}/PE.kml`,
    `${statesPath}/QC.kml`,
    `${statesPath}/SK.kml`,
    `${statesPath}/YT.kml`,
];

//// ADD & Edit MAP

export const prefixes = ["Mr.", "Mrs.", "Ms.", "Dr.", "Prof.", "Rev."];
export const suffixes = ["Jr.", "Sr.", "II", "III", "M.D.", "Ph.D."];
